.desc-header {
    font-size: 10px;
    position: absolute;
    color: gray;
}

.site-detail {
        position: absolute;
        left: 50%;
        right: 50%
        // transform: translate(-50%, -50%);
}
