.map-container {
  flex: 1;

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
}

.top-panel {
  color: wheat;
  // padding: 10%;
  font-size: xx-large;
  // background-color: orange;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 101
}


.loading-wrapper {
  background-color: #e3f9fc;
  z-index: 100;
  // #e3f9fc;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 1s ease-in-out;

  &:not(.active) {
    opacity: 0;
    // transform: scale(0.1);
  }

}

.icon.hoverable svg {
  transition: all 100ms ease-in-out;
  &:hover {
    transform: scale(1.1);
    // box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
    // transform: translateY(-5px);
  }
}



$indigo: rgb(63, 81, 181);
$red: rgb(209, 9, 49);
$orange: rgb(209, 108, 13);
$green: rgb(19, 185, 19);
$dark-green: rgb(6, 95, 70);
$sky-blue: hsl(207, 61%, 53%);
$blue: rgb(37, 99, 235);
$brown: rgb(161, 97, 58);
$yellow: rgb(255, 219, 79);
$mild-yellow: rgb(255, 233, 148);
$folder-color: rgb(255, 202, 40);
$file-color: rgb(144, 202, 249);
$pale-white: rgb(244, 228, 228)
