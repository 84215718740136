// .icon-wrapper{
//     display: flex;
//     height: 60px;
//     justify-content: center;
//     align-items: center;
//     margin-right: -1px;
//     cursor: pointer;   
// }
@import 'src/MapUI/home.scss';


.about {
    position: absolute;
    background-color: #e7edda;
    margin: 1px;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2%;
    left: 0;
    bottom: 65px;
    border-radius: 2%;
    transition: 3ms all-in-ease-out;
    border: 1px solid;
    font-size: 13px;

    .heading {
        border-bottom: 1px solid;
        background-color: #a65238;
        color: white;

    }
}

a {
    text-decoration: none;
}


.section {
    padding: 10px;

    &.maps {
        align-items: center;
        display: flex;

        .left {
            flex: 1;

            >div {
                display: flex;
                padding: 3px 0;
            }
        }

        .improve {
            color: $file-color;
        }

        .is-hidden {
            visibility: hidden;
        }
    }
}

.map-style {
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0;
    padding-bottom: 4px;
    padding-left: 5px;

    .map-style-list {
        border: 1px solid;
        height: 50px;
        width: 50px;
        background-color: #e41616;
        margin-right: 10px;
        margin-left: 4px;
        cursor: pointer;
        border-radius: 4px;

        .map-style-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover {
            border: solid 2px green;
          }
    }
}



.tab-wrapper-map {
    display: flex;
    flex-direction: column;
    background: white;
   
    // margin: 5px 15px;
}

.read-more-button {
    color: red;
}

img {
    width: 400px;
    height: 200px;
}

.two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-header {
    font-size: 10px;
    left: 0;
    position: absolute;
    color: gray;
}

.card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
