@font-face {
    font-family: 'UberMove';
    src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Light.woff2')
        format('woff2'),
      url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Light.woff')
        format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'UberMove';
    src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Regular.woff2')
        format('woff2'),
      url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Regular.woff')
        format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'UberMove';
    src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Medium.woff2')
        format('woff2'),
      url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Medium.woff')
        format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'UberMove';
    src: url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Bold.woff2')
        format('woff2'),
      url('https://d1a3f4spazzrp4.cloudfront.net/dotcom-assets/fonts/UberMove-Bold.woff')
        format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  .tooltip {
    font-family: "UberMove";
    position: absolute;
    padding: 4px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 12px;
    line-height: 16px;
    z-index: 9;
    pointer-events: none;
  }
  
  .control-panel {
    font-family: "UberMove";
    font-size: 14px;
    line-height: 18px;
    width: 284px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    margin: 24px;
    padding: 12px 24px;
    position: absolute;
    top: 20px;
    right: 0;
    outline: none;
    cursor: auto;
  }
  
  .control-panel h3 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 8px 0;
  }
  
  .control-panel a {
    display: inline;
  }
  
  .control-panel p {
    margin-bottom: 16px;
  }
  
  .control-panel .legend {
    display: inline-block;
    width: 12px;
    height: 12px;
  }
  .control-panel hr {
    margin: 12px -24px;
  }
  .control-panel .source-link {
    text-align: right;
    margin-top: 8px;
  }
  .control-panel .source-link a {
    font-weight: bold;
    color: #486865;
    font-size: 11px;
  }
  .control-panel .input {
    position: relative;
    display: flex;
    width: 100%;
  }
  
  .control-panel .input>* {
    vertical-align: middle;
    white-space: nowrap;
  }
  .control-panel .input label {
    text-transform: uppercase;
    width: 50%;
    margin-right: 10%;
    color: #486865;
    margin-bottom: 4px;
  }
  .control-panel .input input, .control-panel .input a {
    font-size: 0.9em;
    display: inline-block;
    padding: 0 4px;
    width: 40%;
    height: 20px;
    line-height: 1.833;
  }
  .control-panel .input input[type="checkbox"],
  .control-panel .input input[type="radio"],
  .control-panel .input input[type="color"] {
    width: 20%;
  }
  .control-panel .input input {
    border: solid 1px #ccc;
  }
  .control-panel .input input:disabled {
    background: #fff;
  }
  .control-panel .input input[type="checkbox"] {
    height: auto;
  }
  
  .control-panel .input .tooltip {
    left: 50%;
    top: 24px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms;
  }
  .control-panel .input:hover .tooltip {
    opacity: 1;
  }
