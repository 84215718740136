.Profile{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
}

.Header{
    flex: 10%;
    display: flex;
    /* background-color: aquamarine; */
}

.Title{
    flex: 10%;
    display: flex;
    /* justify-content: center; */
    margin-top: 0;
    margin-bottom: 0;
    padding: 16px;
}

.ContentBar{
    display: flex;
    flex: 40%;
    /* background-color: chocolate; */
}

.Footer{
    display: flex;
    flex: 10%;
    /* background-color: white; */
    align-items: center;
    justify-content: center;
}

.top-wrapper{
    background-color: #a228280d;
}

/* .profile-wrapper{
    margin-top: 6%;
} */

.bread-crumbs {
    /* margin-top: 6%; */
    padding-left: 1%;
}

.bread-crumb-wrapper {
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 6%;
    justify-content: space-between;
    padding-right: 1%;
}




