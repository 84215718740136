.ContentBar{
    /* background-color: blueviolet; */
    height: 100%;
    flex-direction: column
}

.FirstBar {
    flex: 50%;
    display: flex;
    background-color: aquamarine;

}
.SecondBar{
    /* background-color: blueviolet; */
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column
}

.Works{
    flex: 30%;
    display: flex;
    background-color:darkcyan;
}
.ConnectedPeople{
    flex: 40%;
    display: flex;
    /* background-color:darkmagenta; */

}
.Sources{
    flex: 30%;
    display: flex;
    background-color:crimson;

}

.Biography{
    flex: 80%;
    display: flex;
    background-color: aquamarine;
}

.InfoBar{
    flex: 20%;
    display: flex;
    background-color: brown;
    flex-direction: column
}

.TopInfo{
    flex: 30%;
    display: flex;
    background-color:white;    
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
}

.Roles{
    display: flex;
    flex: 70%;
    background-color: chocolate;
}

.Footer{
    display: flex;
    flex: 10%;
    background-color: white;
    align-items: center;
    justify-content: center;
}


