.fade-out-text {
  opacity: 1;
  transition: opacity 1s;
}

.fade-out-text:hover {
  opacity: 0;
}

.banner {
  font-size: 18px;
}

.vertical-timeline::before {
  background: #833636
}

.accordion {
  width: 40%;
  height: 100%;
  margin: auto;
  padding: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.accordion--row {
  flex-direction: row;
  width: 100%;
  gap: .5rem;
  height: 90vh;
}

.accordion__item { 
  flex-basis: 10%;
  height: 100%;
  margin-bottom: 1em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: helvetica;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px .3em #e2aa56;
  transition: 
    flex-grow .3s linear,
    background-color 0.2s ease-in;
  img { 
    transition: all 500ms ease-in-out;
  }
  &.active {
    // box-shadow: 1px 1px .1em #67adb5;
    box-shadow: 1px 1px .1em #e2aa56;
    color: white;
    flex-grow: 3;
    img {
      transition: all 1500ms ease-in-out;
    }
  }
  &:not(.active) img {
    // filter: brightness(0.6) sepia(0.6);
    filter: brightness(0.6) sepia(0.9);
  }
}

.accordion__text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #232323;
  font-size: 18px;
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%; 
  text-overflow: ellipsis;
  // backdrop-filter: blur(10px);
  // padding: 20px;
  // border: 10px;
  width: 100%;
  height: 100%;
}

 .sticky-header{
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 500;
    max-width: 3840px;
    background-color: #fff9f9;
  }
  
  .header{
    border-width: 0 0 3px;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg,rgba(184,134,45,0),#b8862d,#ffdf91,#b8862d,rgba(184,134,45,0));
    border-style: solid;
    position: relative;
    z-index: 500;
    background-color: #fff9f9;
    color: #cda351; 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
  } 
  
 .view-lang{
  padding: 10px 15px 10px 15px;
  color: #ffffff;
  background-color: #b8862d;
  border-radius: 30px;
 }

 .view-lang:hover {
  background-color: rgb(162, 40, 40);
}
  
  .navbar {
    border-width: 3px 0;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg,rgba(184,134,45,0),#b8862d,#ffdf91,#b8862d,rgba(184,134,45,0));
    border-style: solid;
    position: relative;
    width: 100%;
    z-index: 400;
    // background: -webkit-gradient(linear,left top,right top,from(#181c29),color-stop(40%,#394766),to(#181c29));
    // background: -webkit-linear-gradient(left,#181c29,#394766 40%,#181c29);
    // background: -o-linear-gradient(left,#181c29,#394766 40%,#181c29);
    // background: linear-gradient(90deg,#181c29,#394766 40%,#181c29);
    // height: var(--navbar-height);
    height: auto;
    // color: #232323;
  }
  
  .navbar__container, .primary-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .site-header{
    z-index: 150;
  }
  
  .primary-nav__menu {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .menu__item {
    position: relative;
    margin: 0;
    cursor: pointer;
    color: white;
  }

  .accordian-wrapper {
    margin-top: 4%;
  }

  .overlay-accordion {
    bottom: unset;
    height: 50px;
    top: 0;
    left: 0;
    right: 0;
  }

  .bg-sec-dark {
    background-color: #EAE5DF;
  }

  // .header-irish{
  //   right: 10%;
  //   position: absolute;
  //   color: red;
  // }

  .header__menu.\--desktop.\--right {
    align-items: center;
    margin-right: 1rem;
    color: rgba(51, 2, 2, 0.9);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}


.case-study-tile{
  position: absolute;
  z-index: 2000;
  color: white;
  top: 20px;
  padding: 5px 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(51, 2, 2, 0.7);
  border-radius: 30px;
  transition: all 1000ms ease-in-out;
}

.cbp-item:hover {
  .case-study-tile {
    top: 60px;
    background-color: transparent;
    font-size: 1.3rem;
  }
}

.footer_color {
  background-color: #fff9f9;
}

.case-study-title-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordered-affiliates {
  font-size: 1.2rem;
  text-align: left;
    justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
