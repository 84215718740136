.network-wrapper {
    height: 100%;
    display: flex;
}

.core-wrapper {
    display: flex;
    // background-color: rgb(187, 236, 238);
    // height: 100%;
    flex: 1;
    position: relative;
}

.panel-left {
    top: 10px;
    left: 0;
    position: absolute;
    z-index: 1;
}

.panel-floating {
    right: 0;
    bottom: 0;
    position: absolute;

}

.panel-content {
    flex: 1;
}

.panel-top {
    right: 0;
    position: absolute;
    z-index: 1000;
}

.tab-wrapper-network {
    background-color: whitesmoke;
    top: 60px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: solid 1px #f0e19e;
    margin: 10px;
    margin: 5px 15px;
    border: 1px solid #999;
    box-shadow: 2px 2px 11px 2px rgba(0,0,0,0.3);
    border-radius: 4px;
    min-width: 350px;
}

.icons-wrapper {
    display: flex;
    flex-direction: row;
    // background-color: #555;
    margin-left: 10px;
    border-radius: 10px;
    // border: solid 1px;
}

.icon-items {
    padding: 10px;
    border-radius: 3px;  
    cursor: pointer;    
    // border-radius: 10px;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color:#f0e19e;
        transform: scale(1.4);
    }
}

.searchTitle-wrapper {
    min-width: 300px;
}

.search-button {
    margin: 10px;
}

.advanced-search-wrapper {
    border-top: 1px solid #cabebe;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
}

.advanced-search-list {
    min-width: 348px;
    padding: 24px;
}

input {
    font: 1rem 'Fira Sans', sans-serif;
}

label {
    margin: 0.4rem;
    flex: 2;
}

.size-range {
    align-items: flex-end;
    display: flex;
    flex: 5;
}

.node-size {
    display: flex;
    flex-direction: row;
}

.panel-right-info {
    position: absolute;
    right: 0;
    top: 70px;
    // min-height: 200px;
    margin: 30px;
}

.threeD-settings {
    border-top: 1px solid  #cabebe;
    padding: 10px;
}

.filter-button {
    margin: 20px;
}

.switches {
    background-color: whitesmoke;
    padding: 10px;
    margin-left: 20px;
    margin: 5px 15px 25px;
    border: 1px solid #999;
    box-shadow: 2px 2px 11px 2px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.switches-pop {
    padding: 10px;
    border-radius: 3px;  
    cursor: pointer;    
    // border-radius: 10px;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color:#f0e19e;
        transform: scale(1.2);
    }
}
