.casestudy-title {
  text-align: "center"; 
  padding: "10px"; 
  color:  #a22828;
  font-weight: bold;
}

.chapter-header-media {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 5%;
}


.chapter-header-media--featured-image {
  background-size: cover;
  background-position: center center;
}

.header-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color:#232323;
  padding: 20px;
  // background-color: #a22828;
  backdrop-filter: blur(5px);
  
}


.para-style {
  font-weight: 400;
  font-size: 1.365em;
  line-height: 1.6;
  margin-bottom: 1em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: justify;
}

.dropcap {
  line-height: .9;
  margin-top: 0.01em;
  display: inline-block;
  color: #232323;
  padding: 0em 0em;
  margin-right: 0.22em;
  margin-bottom: 0em;
  vertical-align: top;
  float: left;
  font-size: 5.2em;
}

blockquote {
  p {
    //  color: #989898;
    color: white;
     margin: 0 0 45px;
     border-left: 0;
    //  background: #ebeced;
    background-color: #894a4adb;
     padding: 30px 30px 10px;
     overflow: auto;
  }
}


.image-container {
display: flex;
align-items: flex-start;
}

.para-style {
margin-right: 20px;
}

// .image-wrapper {
// position: relative;
// }

.image {
width: auto;
height: 600px;
margin: 10px;
}

figcaption {
// position: absolute;
// bottom: 0;
// left: 0;
color: #989898;

}

.text-container {
padding: 10px;
}


.insider-subscribe {
  background-image: url(http://localhost:3001/images/case-studies/spenser/fig3.webp);
  background-size: cover;
  background-position: 0 100%;
  background-position-y: bottom;
  padding-top: 6rem;
  padding-bottom: 10.5rem;

}


.page-container {
  position: relative;
  width: 100%;
  // width: calc(83% + var(--page-margin)*2);
  // max-width: calc(3184px + var(--page-margin)*2);
  // padding-left: var(--page-margin);
  // padding-right: var(--page-margin);
  margin-left: auto;
  margin-right: auto;
}

.insider-subscribe__card {
max-width: 50%;
margin-right: 0;
background-blend-mode: overlay;
background-size: 256px,100%;
background-image: url(https://www.ageofempires.com/wp-content/themes/ageOfEmpires/dist/images/ui/textile-60c.webp),linear-gradient(#394766,#292418);
position: relative;
margin-bottom: 1.5rem;
color: #c7c7c7;
text-shadow: 1px 1px 0 rgba(0,0,0,.6);
box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.6);
margin-left: auto;
padding: 10px;
}
// @media (min-width: 768px) {
//   .image-container {
//     flex-direction: row;
//   }

//   .image-wrapper {
//     flex: 1;
//     text-align: left;
//   }

//   .image {
//     max-width: none;
//     height: 580px;
//     margin: 10px;
//   }

//   .text-container {
//     flex: 1;
//     padding: 0 10px;
//   }
// }

.image-wrapper {
flex: 1;
text-align: left;
}

.image {
max-width: none;
height: 580px;
margin: 10px;
}

.text-container {
flex: 1;
padding: 0 10px;
}


.case-study-top-wrapper {
  display: flex;
}

.case-study-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-size: 7rem;
  justify-content: center;
  align-items: center;
    // font-family: Futura;
  background-color: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(245,245,245,0.5) 3px 5px 1px;
  font-family: 'IM Fell English', serif;
  color: rgba(51, 2, 2, 0.9)
}

.case-wrapper {
  // background-color: whitesmoke;
  background-color: #eae5df;
}

.title-img-wrapper {
  height: "600px";
  position: relative;
  overflow: hidden;
}


.title-img-wrapper img {
  width: 100%;
  height: auto;
  // clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  // transform: skewY(-10deg);

}


.image-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 20px;
}

.case-flex-column {
  display: flex;
  flex-direction: column;
}

.case-flex-row {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .case-flex-row {
    display: block;
  }
}

.unorder-list {
  align-items: left;
  display: flex;
  flex-direction: column;
  text-align: justify;
  list-style-type: square;

}

h5 {
  font-family: 'Verdana, Geneva, Tahoma, sans-serif';
}

.bio-list {
  padding-bottom: 15px;
  font-size: 1.1rem;

}

figcaption {
  padding-top: 10px;
}
