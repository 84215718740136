// Form{
//     position: absolute;
//     color: white
// }

.filter-wrapper {
    // position: relative;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // padding: 10px;
}

.info-wrapper {
    position: absolute;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    // padding: 10px;
}

.filter-container { 
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 5px 15px;
    border: 1px solid #999;
    box-shadow: 2px 2px 11px 2px rgba(0,0,0,0.3);
    border-radius: 4px;

    .header {
        height: 30px;
        padding: 0 10px;
        font-size: 13px;
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: center;
        background-color: #a65238;

        .name {
            flex: 1;
            padding: 0 10px;
            color: #ffffff;
            font-size: 1.1rem;
            padding: 20px;
            font-family: open sans-serif;
        }        
    }
}

.header-end-wrapper {
    padding-top: 10px;
}

.filter-body {
    flex: 1;
    font-size: 13px;
    background-color:  #e7edda ;
    padding: 10px;
    min-width: 250px;


    .section-legend {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        padding: 10px 0px 5px 0;
    }

    .section-info {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        padding: 5px 0 0;

        .collapse-icon-wrapper {
            padding-bottom: 5px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            opacity: 0.75;
            transition: opacity 0.3s ease-in-out;
            padding: 10px;

            .collapse-header {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                .icon {
                    align-items: center;
                    display: inline-flex;
                    justify-content: center;
                    height: 1.5rem;
                    width: 1.5rem;
                }

                .title {
                    // margin-right: 4px;
                }

                .hline {
                    flex: 1;
                    margin-left: 8px;
                    height: 1px;
                    background-color: #a22828;
                }
            }
            
        }

       
    }
}


.filterContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
}


.filterContainer-2 {
    position: relative;
    color: rgb(208, 200, 200);
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(153, 149, 149),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    padding: 10px;
}

.mapIcon {
    display: flex;
    flex-direction: row;
    position: relative;
}

.mapStyle {
    position: relative;
    display: flex;
    // box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(153, 149, 149),
    // 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 10px;
    width: 70px;
    height: 70px;
    left: 10px;
    border: 1px solid;
    align-items: self-end;

}

.mapStyle:hover+.mapStyleList {
    // display: block;
    // visibility: visible;
    transition: opacity 0s;
    opacity: 1;
}

.mapStyleList {
    // display: none;
    // opacity: 0;
    // visibility: hidden;
    // transition: 0.3s;

    transition: opacity 5s;
    border: 1px solid;
    display: flex;
    flex-direction: row;
    background-color: rgb(199, 134, 49);
    padding: 10px;
    height: 100px;
    width: 180px;
}

.mapStyleList:hover {
    opacity: 1;
    transition: opacity 0.1s;
}

#terrain {
    width: 60px;
    height: 60px;
    // background: url(../../assets/mapStyles.png) -256px 0;
    background-color: brown;
}

#dark {
    width: 60px;
    height: 60px;
    // background: url(../../assets/mapStyles.png) 0 0;
}

.siteTypeClick {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    // font-family: sans-serif;
    margin-top: -15px;
    margin-bottom: -15px;
}

.siteTypeClick.active {
    font-weight: bolder;
    stroke: black;
    transform: translateX(5px) scale(1); 
    // font-size: 10px; 
    // color: #530e85;
}

.pointer {
    height: 50px;
    width: 50px;
    transform: scale(0.7);
    transition: all 100ms ease-in-out;
    &:hover {
        transform: scale(0.8);
    }
}

.pointer-1 { fill: rgb(76, 0, 153) }
.pointer-2 { fill: rgb(219, 209, 27) }
.pointer-3 { fill: rgb(4, 145, 197) }
.pointer-4 { fill: rgb(212, 140, 16) }
.pointer-5 { fill: rgb(203, 6, 6) }
.pointer-6 { fill: rgb(216, 22, 200) }
.pointer-7 { fill: rgb(0, 153, 0) }


.turn-icon {
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
    /* add transition property */
}

.turn-icon.active {
    transform: rotate(90deg);
}

// .form-font {
//     padding-top: 10px;
//     font-family: open sans-serif;
// }
